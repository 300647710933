/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

import banner from "~assets/images/about-banner.jpg";

class NotFoundPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setNavText(`Home`);
  }

  //

  render() {
    return (
      <>
        <SEO pageTitle="Not Found" />

        <Layout className="_404 w-full bg-sun-yellow gpu">
          <div className="_404__inner">
            <section
              className="_404__banner w-full relative"
              style={{
                backgroundImage: `url(${banner})`
              }}
            >
              <article
                className="_404__banner__inner animation-appear w-3/4 top-0 left-0 absolute px-6 xs:px-3 pt-2 xs:py-8"
                style={{ animationDelay: `100ms` }}
              >
                <h1 className="_404__banner__text w-full f1 font-medium text-sun-yellow">
                  Page not found
                </h1>
              </article>
            </section>
          </div>
        </Layout>
      </>
    );
  }
}

const NotFoundPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <NotFoundPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default NotFoundPage;
